import React from 'react'
import Events from './Events'
import { Title } from '@umahealth/occipital'
import '../styles/historyResume.scss'
import { IHistorySections } from '@umahealth/entities'

interface EventsHistory {
	HistorySection: IHistorySections[]
}

function EventsHistory({ HistorySection } : EventsHistory ) {
	return (
		<section>
			<Title className='bg-primary px-4 py-4 rounded-t-md' hierarchy='h2'  weight='font-semibold' color='text-grey-6' size='text-s'>
				Mi historial
			</Title>
			<Events sections={HistorySection}/>
		</section>
				
	)
}

export default EventsHistory