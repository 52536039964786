import React from 'react'
import { IMedicalRecord, IPatient, IShortcut } from '@umahealth/entities'
import ButtonService from '@/components/HomePage/Components/ServiceButton/ServiceButton'
import { Timestamp } from 'firebase/firestore'
import moment from 'moment'
import { IconsNames, Loader } from '@umahealth/occipital'
import useContextUid from '@/services/firebase/useContextUid'
import { usePendingMedicalRecordsSpecialist } from '@umahealth/fe-firebase'
import makeSpecialistPath from '@/components/Especialista/Utils/makeSpecialistPath'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import { isFarmatodo, isOspreraClient } from '@/utils/validations/ValidateCoverage'
import { firestore } from '@/config/DBConnection'
import { useRouter } from 'next/navigation'
import { IClientState } from '@/config/clients'

interface OnlineDoctorShortcut {
	shortcut: IShortcut
	user: IPatient|null
	client: IClientState
}

interface ExtendedMedicalRecord extends IMedicalRecord {
	isSooner: boolean,
	timeLeft: number
}

function SpecialistShortcut({shortcut, client} : OnlineDoctorShortcut) {
	let specialistPath :string = shortcut.link
	const uid = useContextUid()
	const track = useTrack()
	const router = useRouter()
	const now = moment()
	
	const pendingMedicalRecords = usePendingMedicalRecordsSpecialist<ExtendedMedicalRecord>(
		firestore,
		uid??'NO',
		{},
		{
			select: (medicalRecords) => {
				if (medicalRecords.length === 0 || medicalRecords === undefined){
					return []
				}
				const medicalRecordsWithTimeleft = medicalRecords.map((medicalRecord) => {
					const dt_assignation = medicalRecord.timestamps.dt_assignation as Timestamp
					const dt_assignation_moment = moment(dt_assignation.toDate())
					const diff = moment.duration(dt_assignation_moment.diff(now))
					const isSooner = Math.abs(diff.asMinutes()) < 30
					const extendedMedicalRecord : ExtendedMedicalRecord =  {
						...medicalRecord,
						isSooner: isSooner,
						timeLeft: Math.abs(diff.asMinutes())
					}
					return extendedMedicalRecord
				})

				return medicalRecordsWithTimeleft.sort( medicalRecord => medicalRecord.timeLeft)
			}})


	const MostSoonerAppointment = pendingMedicalRecords.data?.find(medicalRecord => Math.min(medicalRecord.timeLeft))
	// Si el appointment tiene el turno dentro de 30 minutos, lo llevo a queue
	const isQueue = MostSoonerAppointment && MostSoonerAppointment.isSooner

	if (isOspreraClient(client)){
		specialistPath = makeSpecialistPath('SelectSpecialtie', {
			patientUid: uid??'NO',
			dependant: false,
			type: 'online'
		})
	}

	if (isQueue)
	{
		specialistPath = `marketplace/queue/${MostSoonerAppointment.assignation_id}`
	}
	if (!isQueue && pendingMedicalRecords?.data?.length) {
		specialistPath = makeSpecialistPath('pending', {
			dependant: false,
			patientUid: uid??'NO',
			type: 'online',
		})
	}

	if (pendingMedicalRecords.isLoading){
		return (
			<Loader size={'size-5'} color='stroke-primary'/>
		)
	}
	if(isFarmatodo(client) && !(!isQueue &&pendingMedicalRecords?.data?.length)){
		specialistPath = '/pharmacovigilance/select'
	}
	const redirectToSpecialist = (specialistLink:string) => {
		track.mutate({
			eventInput: isFarmatodo(client) ? '[Home-Farmatodo] Clic en módulo Nuevo Encuentro' : '[Home] Clic en módulo Mi Especialista'
		})
		router.push(specialistLink)
	}

	return (
		<ButtonService
			id={shortcut.field}
			highlight={isQueue ? true : undefined}
			isNew={shortcut.new ? true : undefined}
			name={isQueue ? 'briefcaseMedical' : shortcut.icon as IconsNames}
			text={isQueue ? 'Sala de espera' : shortcut.text}
			action={()=> redirectToSpecialist(specialistPath)}
			newText={shortcut.newText ?? 'nuevo'}
		/>
	)
}

export default SpecialistShortcut