'use client'
import React, { useContext, useEffect, useState } from 'react'
import Modules from './Modules/Modules'
import { HomeParametersObject, IPatient } from '@umahealth/entities'
import { IClientState } from '@/config/clients'
import { chatAttContext } from '@/OldRouter/Providers/ProviderChatAtt'
import CountryModal from '../Modals/components/CountryModal'
import NotificationCard from './Components/NotificationCard'
import { usePostHog } from 'posthog-js/react'
import NpsSurvey from '../GeneralComponents/NpsSurvey/NpsSurvey'
import { Survey } from 'posthog-js'
import { usePosthogIdentifyClient } from '@/hooks/usePosthogIdentifyClient'

interface IHomepage {
	homeParameters: HomeParametersObject
	client: IClientState
	country: 'AR' | 'MX' | undefined,
	npsSurveyFlag?: {
		active: boolean,
		key: string,
	},
	patient: IPatient
}

function Homepage({
	client,
	homeParameters,
	country,
	npsSurveyFlag,
	patient,
}: IHomepage) {
	const chatAtt = useContext(chatAttContext)
	const [countrySelected, setCountrySelected] = useState<string | null>(null)
	const dbCountryCoincidence = patient?.country !== process.env.NEXT_PUBLIC_COUNTRY

	const posthog = usePostHog()
	const [activeSurvey, setActiveSurvey] = useState<Survey>()

	usePosthogIdentifyClient(patient)
	
	useEffect(() => {
		setCountrySelected(window.localStorage.getItem('selectedCountry'))
	}, [])
	
	useEffect(() => {
		if (patient && posthog) {
			posthog.getSurveys((surveys) => {
				if (surveys.length && npsSurveyFlag?.active) {
					/** buscamos en el array de todas las surveys definidas en posthog, la que corresponda con nuestra flag key */
					const targetingSurvey = surveys.find((survey) => survey.targeting_flag_key === npsSurveyFlag.key)
					setActiveSurvey(targetingSurvey)
				}
			}, true)
		}
	}, [patient, posthog])

	/**
	 * El modal aparece sí y sólo sí se cumplen estas 3 normas:
	 * El paciente tiene data y además de tener data, su country (del perfil del usuario) difiere del country del entorno env, es decir: patient.data?.country != process.env.NEXT_PUBLIC_COUNTRY
	 * Si no tengo el param country='AR' | country='MX' | country='VE' o lo que sea
	 * Si el valor de localstorage con el country almacenado es diferente a el country del servidor
	 */
	if (
 		dbCountryCoincidence &&
		countrySelected != process.env.NEXT_PUBLIC_COUNTRY &&
		country === undefined

	) {
		return (
			<>
				<CountryModal
					patientCountry={patient?.country}
					serverCountry={process.env.NEXT_PUBLIC_COUNTRY}
				/>
			</>
		)
	}

	return (
		<>
			<Modules
				chatAtt={chatAtt}
				client={client}
				user={patient || null}
				homeParameters={homeParameters}
			/>
			<NotificationCard />
			{activeSurvey && (
				<NpsSurvey
					survey={activeSurvey}
					posthog={posthog}
				/>
			)}
		</>
	)
}

export default Homepage
