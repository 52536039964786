/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
interface onlinedoctor_who {
	type: 'online'|'consultorio',
	redirect: 'onlinedoctor'|'marketplace'|'history'
}
/**
 * Interfaz que define los parámetros para diferentes rutas.
 * @typedef {object} params
 * @deprecated no tiene tanto sentido con los params desde un page.tsx de Nextjs, usar los params de acuerdo a los directorios y ya.
 */
export interface params {
	'onlinedoctor': {
		onboarding: { id : string }
		selectCoverage: { id: string }
		reason:  { id: string, coverage: string }
		questions: { id: string, coverage: string }
		confirmAppointment: { id: string, coverage: string }
		queue: {assignationId: string}
		attention: {assignationId: string}
		claim: {assignationId: string}
		suspended: {assignationId: string}
		done: {assignationId: string}
		cancel: {assignationId: string}
		support: {assignationId: string}
	}
}

type SpecialistSteps = 'calendar'
|'marketplace'
|'SelectSpecialtie'
|'selectCorporate'
|'reasons'
|'confirm'
|'mercadopagoResult'
|'done'
|'pending'
|'profile'
|'selectAttType'
|'prescriptionRequest'
|'confirmPrescription'

type SpecialistType = 'online' | 'consultorio'

/**
 * Interfaz que define la estructura de las rutas de la aplicación.
 * @typedef {object} Routes
 * @property {object} home - Rutas para la sección 'home'.
 * @property {object} onlinedoctor - Rutas para la sección 'onlinedoctor'.
 * ... (resto de las propiedades)
 */
interface Routes {
	'home': {
		home: () => '/'
	}
	/*
	'Oldspecialist': {
		selectWho: () => `/marketplace`,
		selectCoverage: () => `/marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&step='SelectCorporate'`,
		selectSpecialty: () => `/marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&coverage=${string}&step='SelectSpecialite'`,
		selectAttType: () => `/marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&coverage=${string}&specialtie=${string}&step='selectAttType'`,
		selectDoctor: () => `marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&coverage=${string}&specialtie=${string}&step=marketplace`
		selectDate: () => `marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&coverage=${string}&specialtie=${string}&doctorUid=${string}&step=calendar`
		selectReason: () => `marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&coverage=${string}&specialtie=${string}&doctorUid=${string}&appointmentId=${string}&step=reasons`
		confirmAppointment: () => `marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&coverage=${string}&specialtie=${string}&doctorUid=${string}&appointmentId=${string}&appointmentReasons=${string}&step=confirm`
		mercadopagoResult: () => `marketplace?patientUid=${string}&dependant=${string|false}&type=${SpecialistType}&appointmentId=${string}&selectMethod=doctor&specialtie=${string}&doctorUid=${string}&appointmentReasons=${string}&step=mercadopagoResult&paid=${boolean}&product=especialista&method=credit_card&amount=916&payment_id=${string}&full_price=1246`
		checkAppointment: () => `specialist/${string}`
		pendingAppointments: () => 'specialist/pendingAppointments'
	}*/
	'onlinedoctor': {
		onboarding: () => '/onlinedoctor/onboarding',
		who:  ({type, redirect} : onlinedoctor_who) => `/onlinedoctor/who?type=${string}&redirect=${string}`,
		selectCoverage: (patientUid: string) => `/onlinedoctor/${string}/selectCoverage`,
		reason: (patientUid: string, coverage: string) =>  `/onlinedoctor/${string}/reason/${string}` ,
		questions: (patientUid: string, coverage: string) => `/onlinedoctor/${string}/questions/${string}`,
		confirmAppointment: (patientUid: string, coverage: string) => `/onlinedoctor/${string}/confirmAppointment/${string}`,
		queue: (assignationId : string) =>   `/onlinedoctor/${string}/queue` ,
		attention: (assignationId : string) =>  `/onlinedoctor/${string}/attention`,
		claim: (assignationId : string) =>    `/onlinedoctor/${string}/claim`,
		suspended: (assignationId : string) => `/onlinedoctor/${string}/suspended`,
		done: (assignationId : string) =>  `/onlinedoctor/${string}/done`,
		cancel: (assignationId : string) =>  `/onlinedoctor/${string}/cancel`,
		support: (assignationId : string) =>  `/onlinedoctor/${string}/support`,
		feedback: (assignationId : string) => `/onlinedoctor/${string}/feedback`
	}
	'history': {
		who: () => '/history',
		selectRecord: (patientUid: string) => `/history/${string}`,
		record: (patientUid: string, assignationId: string) => `/history/${string}/${string}` 
		resume: (patientUid: string, assignationId: string) => `/history/${string}/${string}/resumen` 
		constancy: (patientUid: string, assignationId: string) => `/history/${string}/${string}/constancia` 
		selectPrescription: (patientUid: string, assignationId: string) => `/history/${string}/${string}/recetas` 
		selectOrder: (patientUid: string, assignationId: string) => `/history/${string}/${string}/ordenes` 
		prescription: (patientUid: string, assignationId: string, prescriptionId: string) => `/history/${string}/${string}/recetas/${string}` 
		order: (patientUid: string, assignationId: string, orderId: string) => `/history/${string}/${string}/ordenes/${string}` 
		conversation: (assignationId: string, patientUid: string) => `/history/${string}/${string}/conversation`
	}
	'chatAtt': {
		feedback: (assignationId: string) => `/feedbackCsat/${string}`
		done: (assignationId: string) => `/chatAtt/${string}/done`
		reason: (patientUid: string, coverage: string) =>  `/chatAtt/reason?patientUid=${string}&coverage=${string}`
	}
	'encounters': {
		selectEncounter: (fhirPatientId: string) => `/encounters/${string}`,
		encounter: (fhirPatientId: string, encounterId:string) => `/encounters/${string}/${string}`,
		consent: (fhirPatientId: string, encounterId:string) => `/encounters/${string}/${string}/consent`
		medicalContact: (fhirPatientId: string, encounterId:string) => `/encounters/${string}/${string}/medicalContact`
		protocol: (fhirPatientId: string, encounterId:string) => `/encounters/${string}/${string}/protocol`
		report: (fhirPatientId: string, encounterId:string) => `/encounters/${string}/${string}/report`
	}
	'observations': {
		prx: (fhirPatientId: string, encounterId:string) => `/observations/${string}/${string}`
	}
	'marketplace': {
		selectSpecialty: (patientUid: string, dependant: boolean, type: SpecialistType, coverage: string) => `/marketplace?patientUid=${string}&dependant=${boolean}&type=${SpecialistType}&step=SelectSpecialtie&coverage=${string}`
	}
}
/**
 * Tipo que representa el tipo de retorno de una función en las rutas.
 * @template T
 * @typedef {ReturnType<T[keyof T]>} RouteReturnType
 */type RouteReturnType<T extends Record<string, (...args: any[]) => string>> = ReturnType<T[keyof T]>;

/**
 * Tipo que combina las rutas de 'home' y 'onlinedoctor'.
 * @typedef {object} CombinedRoutes
 * ... (resto de las propiedades)
 */
type CombinedRoutes = Routes['home'] & Routes['onlinedoctor'];

export type ValidRoutes = RouteReturnType<CombinedRoutes>;

export const routes = {
	home: {
		home: () => '/'
	},
	onlinedoctor: {
		onboarding: () => '/onlinedoctor/onboarding',
		who: ({type, redirect = 'onlinedoctor'}) => `/onlinedoctor/who?type=${type}&redirect=${redirect}`,
		selectCoverage: (patientUid: string) => `/onlinedoctor/${patientUid}/selectCoverage`,
		reason: (patientUid: string, coverage: string) => `/onlinedoctor/${patientUid}/reason/${coverage}`,
		questions: (patientUid: string, coverage: string) => `/onlinedoctor/${patientUid}/questions/${coverage}`,
		confirmAppointment: (patientUid: string, coverage: string) => `/onlinedoctor/${patientUid}/confirmAppointment/${coverage}`,
		queue: (assignationId : string) =>  `/onlinedoctor/${assignationId}/queue`,
		attention: (assignationId : string) =>  `/onlinedoctor/${assignationId}/attention`,
		claim: (assignationId : string) =>  `/onlinedoctor/${assignationId}/claim`,
		suspended: (assignationId : string) =>  `/onlinedoctor/${assignationId}/suspended`,
		done: (assignationId : string) =>  `/onlinedoctor/${assignationId}/done`,
		cancel: (assignationId : string) =>  `/onlinedoctor/${assignationId}/cancel`,
		support: (assignationId : string) =>  `/onlinedoctor/${assignationId}/support`,
		feedback: (assignationId : string) => `/onlinedoctor/${assignationId}/feedback`

	},
	history: {
		who: () => '/history',
		selectRecord: (patientUid: string) => `/history/${patientUid}`,
		record: (patientUid: string, assignationId: string) => `/history/${patientUid}/${assignationId}` ,
		resume: (patientUid: string, assignationId: string) => `/history/${patientUid}/${assignationId}/resumen` ,
		constancy: (patientUid: string, assignationId: string) => `/history/${patientUid}/${assignationId}/constancia` ,
		selectPrescription: (patientUid: string, assignationId: string) => `/history/${patientUid}/${assignationId}/recetas` ,
		selectOrder: (patientUid: string, assignationId: string) => `/history/${patientUid}/${assignationId}/ordenes` ,
		prescription: (patientUid: string, assignationId: string, prescriptionId: string) => `/history/${patientUid}/${assignationId}/recetas/${prescriptionId}` ,
		order: (patientUid: string, assignationId: string, orderId: string) => `/history/${patientUid}/${assignationId}/ordenes/${orderId}`,
		conversation: (assignationId: string, patientUid: string) => `/history/${patientUid}/${assignationId}/conversation`
	},
	chatAtt: {
		feedback: (assignationId: string) => `/feedbackCsat/${assignationId}`,
		done: (assignationId: string) => `/chatAtt/${assignationId}/done`,
		reason: (patientUid: string, coverage: string) =>  `/chatAtt/reason?patientUid=${patientUid}&coverage=${coverage}`
	},
	encounters: {
		selectEncounter: (fhirPatientId: string) => `/encounters/${fhirPatientId}`,
		encounter: (fhirPatientId: string, encounterId:string) => `/encounters/${fhirPatientId}/${encounterId}`,
		consent: (fhirPatientId: string, encounterId:string) => `/encounters/${fhirPatientId}/${encounterId}/consent`,
		medicalContact: (fhirPatientId: string, encounterId:string) => `/encounters/${fhirPatientId}/${encounterId}/medicalContact`,
		protocol: (fhirPatientId: string, encounterId:string) => `/encounters/${fhirPatientId}/${encounterId}/protocol`,
		report: (fhirPatientId: string, encounterId:string) => `/encounters/${fhirPatientId}/${encounterId}/report`
	},
	observations: {
		prx: (encounterId: string, observationId:string) => `/observations/${encounterId}/${observationId}`,
	},
	marketplace: {
		selectSpecialty: (patientUid: string, dependant: boolean, type: SpecialistType, coverage: string) => `/marketplace?patientUid=${patientUid}&dependant=${dependant}&type=${type}&step=SelectSpecialtie&coverage=${coverage}`,
	}
} as const satisfies Routes