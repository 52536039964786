import { HomeParametersObject, IPatient, chat } from '@umahealth/entities'
import Banner from '@/components/GeneralComponents/Banner/HomeSlider/Banner'
import React from 'react'
import EventsHistory from '../EventsHistory/EventsHistory'
import Shortcuts from './Shortcuts/Shortcuts'
import styles from './Shortcuts/styles.module.scss'
import useActiveDerivations from '@/services/hooks/Firebase/useActiveDerivations'
import useContextUid from '@/services/firebase/useContextUid'
import { firestore } from '@/config/DBConnection'
import { IClientState } from '@/config/clients'
import { Button } from '@umahealth/occipital'
import RegretButton from '../Components/RegretButton'

interface IModules {
	user: IPatient|null,
	homeParameters: HomeParametersObject
	client: IClientState
	chatAtt: chat|null
}

function Modules({ user, client, homeParameters, chatAtt}: IModules) {
	const uid = useContextUid()
	const derivations = useActiveDerivations(firestore, uid??'NO')

	/** El orden de renderizado es importante */
	return (
		<div className={styles['home-container']}>
			{derivations.data?.active && derivations.data?.status !== 'Finalizado/No registrado' && homeParameters['derivation']?.active && (
				<div className='mb-3' key="derivations">
					<Button className='flex' variant='filled' type='link' href={`/derivations/${uid}`}>
						Derivación hospitalaria en curso
					</Button>
				</div>
			)}
			{homeParameters['header_carousel']?.active && (
				<div key="carousel mb-10">
					<Banner
						slidesInfo={homeParameters['header_carousel']?.slides.sort(
							(a, b) => a.order - b.order
						)}
						autoplay={homeParameters['header_carousel']?.autoplay}
					/>
				</div>
			)}
			{homeParameters['shorcuts_content']?.active && (
				<div className='mb-5'>
					<Shortcuts chatAtt={chatAtt} client={client} shortcutParameter={homeParameters['shorcuts_content']} user={user} />
				</div>
			)}
			{homeParameters['history']?.active && (
				<React.Fragment key="history">
					<EventsHistory HistorySection={homeParameters['history']?.sections} />
				</React.Fragment>
			)}
			<RegretButton />
		</div>
	)
}

export default Modules
