import { IPatient } from '@umahealth/entities'
import Cookies from 'js-cookie'
import { usePostHog } from 'posthog-js/react'
import { useEffect } from 'react'

export const usePosthogIdentifyClient = (patient: IPatient) => {
	const posthog = usePostHog()
	const isClientIdentified = Cookies.get('posthog_identified_client')
	useEffect(() => {
		if (!isClientIdentified && patient && posthog) {
			posthog.identify(patient.id)
			Cookies.set('posthog_identified_client', 'true', {
				expires: 1, // Expira en 1 dia
				path: '/' // Disponible en todas las rutas
			})
		}
	}, [isClientIdentified, patient, posthog])
}
