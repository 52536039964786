'use client'
import React, { BaseSyntheticEvent } from 'react'
import { IDependant, IPatient } from '@umahealth/entities'
import { InputDni, InputDob, InputEmail, InputFirstname, InputLastname, InputNationality, InputSex, InputWs } from '@/components/User/SignUp/Register/utils/Inputs'
import IFormRegister from '@/components/User/SignUp/Register/utils/registerInputs'
import { Button, Paragraph, Title } from '@umahealth/occipital'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useUpdatePatient } from '@/services/hooks/requests/Requests'
import { useRouter } from 'next/navigation'
import { queryClient } from '@/config/queryClient'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import Status from '@/components/GeneralComponents/Problem/Status'
import {countriesListWithLabel} from '@/config/countries'
import moment from 'moment'
import { revalidatePatient } from '@/app/services/bothServices/revalidatePatient'
import { ICheckInfo } from '@/utils/patientUtils/checkPatientInfo'
import useWsModalValidation from '@/components/User/SignUp/Register/Components/WsModalValidation'

interface ICompletePatientInfo {
	/** patient object, ya sea main user o dependant */
	patient: IPatient | IDependant,
	patientCheck: ICheckInfo,
	/** uid del user principal */
	userUid: string | undefined,
	isDependant?: boolean,
}

function CompletePatientInfo({ patient, isDependant, userUid, patientCheck }: ICompletePatientInfo) {
	const history = useRouter()
	const formReturn = useForm<IFormRegister>()
	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		control,
		setError,
	} = formReturn

	const track = useTrack()

	const dependantUid = isDependant ? patient.id : false
	const updatePatient = useUpdatePatient({
		user: userUid ?? 'NO',
		dependant: dependantUid
	})

	const onSubmit: SubmitHandler<IFormRegister> = (formInputs, event?: BaseSyntheticEvent) => {
		/** Si recibe event, quiere decir que se llamo a onSubmit desde el handleSubmit del form.
		 * Si esto ocurre, y ademas existe el input de telefono, quiero abrir el modal para validar el telefono.
		 */
		const shouldValidate = !!event
		if (shouldValidate && formInputs.ws) {
			return setModalSheetProps((prevState) => openModalWs(prevState, 0))
		}
		const country = countriesListWithLabel.find(element => element.value === watch('nationality') || element.label === watch('nationality'))
		const countryNotFound = formInputs.nationality && !country
		/** Chequeo si el país lo tomaron del listado */
		if (formInputs.nationality && countryNotFound) {
			setError('nationality',{ type: 'custom', message: 'Seleccioná una nacionalidad del listado, si no encuentra la suya comuniquese a info@uma-health.com' } , {shouldFocus: false})
			return
		}

		if (formInputs.dob) {
			formInputs.dob = moment(formInputs.dob).format('YYYY-DD-MM')
		}

		if (formInputs.nationality && country?.value){
			formInputs.nationality = country?.value 
		}

		updatePatient.mutate(formInputs, {
			onSuccess: () => {
				queryClient.clear()

				track.mutate({
					eventInput: '[APLICACIÓN] Datos del paciente actualizados correctamente'
				}, {
					onSuccess: () => {
						history.refresh()
					},
					onError: () => {
						history.refresh()
					}
				})
				revalidatePatient()
			}
		})
	}

	const {
		modalWsComponent,
		setModalSheetProps,
		openModalWs,
	} = useWsModalValidation<IFormRegister>({
		formReturn,
		onSubmit,
	})

	if (updatePatient.isError){
		return <Status type='warning' title='No logramos actualizar tus datos principales' subtitle='Nuestro equipo ya está trabajando para solucionarlo. Podés enviar un email a info@uma-health si el problema persiste' error={updatePatient.error}>
			<Button variant='filled' size='full' type='button' action={() => history.push('/')}>
				Ir al inicio
			</Button>
		</Status>
	}
	
	return (
		<form
			className='flex flex-col h-full justify-between relative overflow-hidden'
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className='h-full flex flex-col overflow-auto p-4 md:px-6 !pb-0'>
				<Title hierarchy='h1' weight='font-semibold' size='text-l' color='text-grey-1'> Actualización de datos </Title>
				<Paragraph
					weight='font-regular'
					size='text-s'
					color='text-grey-1'
					className=''
				>
					Hay algunos datos de {isDependant ? `${patient.chosenName || patient.fullname || 'tu paciente'}` : 'tu perfil'} que necesitamos actualizar para continuar
				</Paragraph>
				{patientCheck.nationalityCheck &&
					<div className='mt-6'>
						<InputNationality<IFormRegister>
							register={register}
							watch={watch}
							fieldError={errors.nationality}
						/>
					</div>
				}
				{patientCheck.fullnameCheck &&
					<>
						<InputFirstname
							register={register}
							watch={watch}
							error={errors.firstname?.message}
						/>
						<InputLastname
							register={register}
							watch={watch}
							error={errors.lastname?.message}
						/>

					</>
				}
				{patientCheck.dniCheck &&
					<InputDni control={control} register={register} watch={watch} error={errors.dni?.message} />
				}
				{patientCheck.dobCheck &&
					<InputDob register={register} watch={watch} error={errors.dob?.message} isDependant={!!dependantUid} />
				}
				{patientCheck.emailCheck &&
					<InputEmail register={register} watch={watch} error={errors.email?.message} />
				}
				{patientCheck.wsCheck &&
					<InputWs control={control} register={register} error={errors.ws?.message} />
				}
				{patientCheck.sexCheck &&
					<InputSex watch={watch} error={errors.ws?.message} control={control} />
				}
			</div>
			<div className='py-6 shadow-footer w-full flex justify-center'>
				<Button
					variant='filled'
					type='submit'
					size='full'
					loading={updatePatient.isLoading}
					className='w-3/4'
				>
					Actualizar datos
				</Button>
			</div>
			{modalWsComponent}
		</form>
	)
}

export default CompletePatientInfo